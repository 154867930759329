@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url("./assets/fonts/Montserrat-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Montserrat-Italic";
  src: url("./assets/fonts/Montserrat-Italic.otf") format("opentype");
}

@font-face {
  font-family: "League Spartan";
  src: url("./assets/fonts/LeagueSpartan-Bold.otf") format("opentype");
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
